.contact-container{
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 10%;
    padding: 0 1rem;
    background-color: grey;
    border-radius: 20px;
    width: 400vh;
    height: 35vh;   
}

 .contact-title{
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #fff;
    text-decoration: overline;
    text-decoration-color: #fff;
    height: 100px;
    text-transform: uppercase;
}



.contact-text{
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    align-self: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  
   
}

.contact-col{
    text-align: center;
    font-size: 1.6;
}

.button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.contact-link{
    margin: 0 1rem;
}

/* Media Queries */

/*  iPhone SE */
@media screen and (min-width:320px){
    .contact-container{
        margin-top: 5%;
        margin-left: 5%;
        margin-right: 5%;
        padding: 0 1rem;
        background-color: grey;
        border-radius: 20px;
        width: 450vh;
        height: 100vh;   
    }
}

/*  iPhone 6/7/8 */
@media screen and (min-width:375px){
    .contact-container{
        margin-top: 5%;
        margin-left: 5%;
        margin-right: 5%;
        padding: 0 1rem;
        background-color: grey;
        border-radius: 20px;
        width: 450vh;
        height: 100vh;   
    }
}

/* Tablet and Mobile */
@media screen and (min-width:768px){
    .contact-container{
        margin-top: 5%;
        margin-left: 5%;
        margin-right: 5%;
        padding: 0 1rem;
        background-color: grey;
        border-radius: 20px;
        width: 450vh;
        height: 100vh;   
    }
}

/* Desktop */
@media screen and (min-width:1024px){
    .contact-container{
        margin-top: 5%;
        margin-left: 5%;
        margin-right: 5%;
        padding: 0 1rem;
        background-color: grey;
        border-radius: 20px;
        width: 450vh;
        height: 45vh;   
    }
}
    
    
    

