.heart{
    color: #f11;
}

.mb-4 .heart-icon{
   background-color: #f11;
    
}

.mb-4 .heart-icon:hover{
    font-size: 15;
    background: #fff;
    transform: scale(1.1);
    color: #f11;
    transition: ease-in-out;
    box-shadow: 0 0 0.2rem #fff;
}


/* Instagram icon  */
.mb-4 .instagram {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    
}

 
.mb-4 .instagram:hover{
    font-size: 15;
    background: #fff;
    transform: scale(1.1);
    color: #fd5949;
    transition: ease-in-out;
    box-shadow: 0 0 0.2rem #fff;
}

/* Linkedin icon */
.mb-4 .linkedin{
    background-color: #0077b5;
}

.mb-4 .linkedin:hover {
    font-size: 15;
    color: #07e;
    transform: scale(1.1);
    box-shadow: 0 0 0.2rem 0.2rem #fff;
    background: #fff;
    transition: ease-in-out;
}


/* Github icon */
.mb-4 .github{
    background-color: #000;
}
.mb-4 .github:hover {
    font-size: 15;
    color: #000;
    transform: scale(1.1);
    box-shadow: 0 0 0.2rem 0.2rem #fff;
    transition: ease-in-out;
}

