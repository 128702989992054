.container2{
  max-width:85%;
  margin: 0 auto;
  padding: 10px;
  background-color:red;
  border-radius: 5%;
  text-align: center;
}

h1,h2 {
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  color:white;
  font-size: 45px;
}

.rosso{
  color: darkred;
  font-size: 50px;
  font-weight: 700;
}

.description-app{
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  color:white;
  font-size: 25px;
  text-align: justify;
}

ul>li {
  list-style: none;
  margin: 0 auto;
  padding: 10px;
  color:white;
  font-size: 25px;

}

ol>li {
list-style: none;
  margin: 0 auto;
  padding: 10px;
  color:white;
  font-size: 25px;
}

.delete{
  color: white;
}

.assistance{
  color: black;
  font-size: larger;
  font-weight: 600;
  font-size: 20px;
}

.app-img{
  width:150px;
  height:200px;
}