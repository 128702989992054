

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
}

.card{
    text-align:'center';
    margin: 10%;
    margin-top:5%;
    margin-left:5%;
    margin-right:5%;
    padding:20;
    align-self: center;
}

