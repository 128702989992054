* {
    margin: 0;
    padding: 0;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px rgb(126, 124, 124);
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(32, 39, 49);
    border-radius: 20px;
  }

body {
        font-family: Roboto, Helvetica, Arial, sans-serif;
}

.main-container {
    background-image: url("../assets/images/background.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    overflow-y:scroll;
}



.profile{
    position: relative;
    left: 15%;
    width: 70%;
    box-shadow:1px 3px 5px #fff;
    border-radius:  2%;
    background-color: #000;
    opacity: 0.9;
}

.description{
    margin: 0 0 auto;
    padding:20px;
    font-family:'Titan One', cursive;
    font-size: 2.4em;
    color: #fff;
    text-align: justify;
}
  

@media screen and (max-width: 300px) {
    .main-container {
        height: 150vh;
        overflow: scroll;
    }
    .profile{
        margin-top: 10%;
        opacity: 1;
        
    }
    .description{
        font-size: 1.3em;        
    }
}

@media screen and (max-width: 900px) {
    .main-container {
        height: 170vh;
        overflow: scroll;
    }
    .profile{
        margin-top: 10%;
        opacity: 0.9;
        
    }
    .description{
        font-size: 1.3em;        
    }
}
    
